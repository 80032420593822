@font-face {
  font-family: 'Avenir-Roman';
  src: local('Avenir-Roman'),
    url(./assets/fonts/Avenir-Roman.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: local('Avenir-Heavy'),
    url(./assets/fonts/Avenir-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Medium';
  src: local('Avenir-Medium'),
    url(./assets/fonts/Avenir-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Black';
  src: local('Avenir-Black'),
    url(./assets/fonts/Avenir-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Book';
  src: local('Avenir-Book'),
    url(./assets/fonts/Avenir-Book.ttf) format('truetype');
}

body,
p,
hr {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar-track {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(0, 23, 145, 0.07);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgb(246, 246, 247);
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #001791;
}

::-webkit-scrollbar-corner {
  display: none;
}
