.react-datepicker {
  border: 0;
  border-radius: 3.3px;
  box-shadow: 0 4px 13px 0 #e4e6ef;
}

.react-datepicker__day-names {
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px 4px 4px -3px #e4e6ef, inset 0px -4px 4px -3px #e4e6ef;
  background-color: rgba(0, 23, 145, 0.03);
  font-family: Avenir-Heavy;
  font-size: 12px;
  font-weight: 900;
  line-height: 17.6px;
  text-align: center;
  color: #505055;
}

.react-datepicker__month-container {
  background-color: #ffffff;
}

.react-datepicker__header {
  background-color: #ffffff;
  border: 0;
  padding: 0;
}

.react-datepicker__current-month {
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: Avenir-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: -0.05px;
  color: #505055;
}

.react-datepicker__month {
  font-family: Avenir-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: center;
  color: #020b41;
}

.react-datepicker__navigation {
  top: 15px;
  border-width: 0;
}

.react-datepicker__navigation {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(0, 23, 145, 0.1);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.react-datepicker__navigation--previous {
  background-image: url('../../../assets/images/arrow-left/arrow-left.png');
}

.react-datepicker__navigation--next {
  background-image: url('../../../assets/images/arrow-right/arrow-right.png');
}

.react-datepicker__day--outside-month {
  opacity: 0.4;
}

.react-datepicker__day {
  font-family: Avenir-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: center;
  color: #020b41;
}

.react-datepicker__day,
.react-datepicker__day-name {
  margin: 0px;
  padding: 12px 8px;
}

.react-datepicker__day--selected {
  border-radius: 3.3px;
  background-color: rgba(0, 23, 145, 0.1);
  outline: none;
}

.react-datepicker__day--selected:hover {
  background-color: #001791;
  color: #e4e6ef;
}

.react-datepicker__day--today,
.react-datepicker__day--selected {
  font-family: Avenir-Black;
  font-size: 12px;
  font-weight: 900;
  line-height: 17.6px;
  text-align: center;
  color: #001791;
}

.react-datepicker__triangle {
  border: 0 !important;
  margin-top: 2px !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 20px solid #ffffff !important;
  border-right: 20px solid transparent !important;
  box-shadow: 4px 4px 13px 0 #e4e6ef !important;
  top: -8px !important;
  left: calc(50% - 14.15px) !important;
  transform: rotate(45deg) !important;
}

.react-datepicker__day--disabled {
  pointer-events: none;
  color: #ccc;
}

@media (max-width: 768px) {
  .react-datepicker__current-month {
    font-size: 12.8px;
    letter-spacing: -0.04px;
  }

  .react-datepicker__navigation {
    width: 17px;
    height: 17px;
  }

  .react-datepicker__day-names {
    font-size: 9.6px;
    line-height: 14.1px;
  }

  .react-datepicker__day,
  .react-datepicker__month {
    font-size: 9.6px;
    line-height: 14.1px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    padding: 10px 6px;
  }
}

.react-datepicker__day--keyboard-selected {
  background: none;
}
