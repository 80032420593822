.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toast {
  padding: 15px 20px;
  color: #d56655;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  position: fixed;
  top: -2.5%;
  right: 50%;
  opacity: 0;
  z-index: 9999;
  box-shadow: 0 1px 4px 0 #e4e6ef;
  transition: all 0.4s ease-out;
  transform: translateX(50%);
  font-family: 'Avenir-Medium';
  max-width: 300px;
}
