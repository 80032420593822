@font-face {
  font-family: 'Avenir-Roman';
  src: local('Avenir-Roman'),
    url(/static/media/Avenir-Roman.3f2cd348.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: local('Avenir-Heavy'),
    url(/static/media/Avenir-Heavy.1ebe38c3.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Medium';
  src: local('Avenir-Medium'),
    url(/static/media/Avenir-Medium.33f01234.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Black';
  src: local('Avenir-Black'),
    url(/static/media/Avenir-Black.9c1b5945.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Book';
  src: local('Avenir-Book'),
    url(/static/media/Avenir-Book.9f784699.ttf) format('truetype');
}

body,
p,
hr {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar-track {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(0, 23, 145, 0.07);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgb(246, 246, 247);
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #001791;
}

::-webkit-scrollbar-corner {
  display: none;
}

.react-datepicker {
  border: 0;
  border-radius: 3.3px;
  box-shadow: 0 4px 13px 0 #e4e6ef;
}

.react-datepicker__day-names {
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px 4px 4px -3px #e4e6ef, inset 0px -4px 4px -3px #e4e6ef;
  background-color: rgba(0, 23, 145, 0.03);
  font-family: Avenir-Heavy;
  font-size: 12px;
  font-weight: 900;
  line-height: 17.6px;
  text-align: center;
  color: #505055;
}

.react-datepicker__month-container {
  background-color: #ffffff;
}

.react-datepicker__header {
  background-color: #ffffff;
  border: 0;
  padding: 0;
}

.react-datepicker__current-month {
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: Avenir-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: -0.05px;
  color: #505055;
}

.react-datepicker__month {
  font-family: Avenir-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: center;
  color: #020b41;
}

.react-datepicker__navigation {
  top: 15px;
  border-width: 0;
}

.react-datepicker__navigation {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(0, 23, 145, 0.1);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.react-datepicker__navigation--previous {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAICAYAAADaxo44AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABqADAAQAAAABAAAACAAAAADUoIPEAAAAnklEQVQIHWNkQAZSM0UY/v3oYmBmns0EF5edpMzw98cxhv+McQz//vNBJCSmmjL8ZjjGwMgoDVQYyPA8dycjg9w0JYZffy4DBX4CsQfDi7xTIFOYGP6xfWD4z/AciPkYGBn0QYIgwMTwJOUdAwOrFQPj/3NAs2cxSExsAkkwgggwkJrJBXTRSqBOHwZGpiSEBEg2dBUzw5HnyQwMzDsBftos/V5JC7oAAAAASUVORK5CYII=);
}

.react-datepicker__navigation--next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAICAYAAADaxo44AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABqADAAQAAAABAAAACAAAAADUoIPEAAAAoUlEQVQIHWNgkJ5iySAxcR6D1EwRBiTAxPDvPx/Df8Y4hr8/jjHITlKGyTExPM/dCeQEMjAySjP8ZjjGIDHVFCTJCFPBID7FnIHx33Ygn52BjUWXCS7B9E+P4T8D0FiG5wz/2D5AJCQmNgHtmsXA+P8cAwOrFcOTlHeMDBKTExn+/5sHNHQLAxNHOMOz9G8gU5iAlu5jYGJMY7CVDIAJgiQA6RIrGdvaTJIAAAAASUVORK5CYII=);
}

.react-datepicker__day--outside-month {
  opacity: 0.4;
}

.react-datepicker__day {
  font-family: Avenir-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: center;
  color: #020b41;
}

.react-datepicker__day,
.react-datepicker__day-name {
  margin: 0px;
  padding: 12px 8px;
}

.react-datepicker__day--selected {
  border-radius: 3.3px;
  background-color: rgba(0, 23, 145, 0.1);
  outline: none;
}

.react-datepicker__day--selected:hover {
  background-color: #001791;
  color: #e4e6ef;
}

.react-datepicker__day--today,
.react-datepicker__day--selected {
  font-family: Avenir-Black;
  font-size: 12px;
  font-weight: 900;
  line-height: 17.6px;
  text-align: center;
  color: #001791;
}

.react-datepicker__triangle {
  border: 0 !important;
  margin-top: 2px !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 20px solid #ffffff !important;
  border-right: 20px solid transparent !important;
  box-shadow: 4px 4px 13px 0 #e4e6ef !important;
  top: -8px !important;
  left: calc(50% - 14.15px) !important;
  transform: rotate(45deg) !important;
}

.react-datepicker__day--disabled {
  pointer-events: none;
  color: #ccc;
}

@media (max-width: 768px) {
  .react-datepicker__current-month {
    font-size: 12.8px;
    letter-spacing: -0.04px;
  }

  .react-datepicker__navigation {
    width: 17px;
    height: 17px;
  }

  .react-datepicker__day-names {
    font-size: 9.6px;
    line-height: 14.1px;
  }

  .react-datepicker__day,
  .react-datepicker__month {
    font-size: 9.6px;
    line-height: 14.1px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    padding: 10px 6px;
  }
}

.react-datepicker__day--keyboard-selected {
  background: none;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toast {
  padding: 15px 20px;
  color: #d56655;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  position: fixed;
  top: -2.5%;
  right: 50%;
  opacity: 0;
  z-index: 9999;
  box-shadow: 0 1px 4px 0 #e4e6ef;
  transition: all 0.4s ease-out;
  transform: translateX(50%);
  font-family: 'Avenir-Medium';
  max-width: 300px;
}

